<template>
  <div
    class="ColorPicker"
  >
    <v-fade-transition leave-absolute>
      <v-avatar
        v-show="!expandedInner"
        v-ripple="{ center: true }"
        size="40"
        :color="color.color"
        class="ColorPicker__handle"
        @click="expandedInner = true"
      />
    </v-fade-transition>
    <v-slide-x-transition
      group
      tag="div"
    >
      <template v-if="expandedInner">
        <v-avatar
          v-for="color in COLORS"
          :key="color"
          v-ripple="{ center: true }"
          color="transparent"
          class="ColorPicker__choice"
          :class="{
            'ColorPicker__choice--selected': color === model,
            // 'ml-2': i > 0,
          }"
          @click="model = color"
        >
          <v-avatar
            size="40"
            :color="COLORS_LOOKUP[color].color"
          />
        </v-avatar>
      </template>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { COLORS_LOOKUP, COLORS } from '../constants'
import { randomChoice } from '../helpers'

export default {
  name: 'ColorPicker',

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: {
      type: String,
      default: () => randomChoice(COLORS),
      validator: value => COLORS.includes(value),
    },
    expanded: { type: Boolean, default: false }, // .sync
  },

  data: () => ({
    COLORS,
    COLORS_LOOKUP,

    model: null,
    expandedInner: false,
  }),

  computed: {
    color() { return this.COLORS_LOOKUP[this.model] },
  },

  watch: {
    // v-model (:value + @input)
    value: {
      handler(value) { this.model = value },
      immediate: true,
    },
    model(model) {
      if (model !== this.value) this.$emit('input', model)
    },

    // :expanded.sync
    expanded: {
      handler(expanded) { this.expandedInner = expanded },
      immediate: true,
    },
    expandedInner(expandedInner) {
      if (expandedInner !== this.expanded) {
        this.$emit('update:expanded', expandedInner)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
  @import '../scss/variables'

  .ColorPicker
    position: relative
    height: 52px

    &__handle
      margin: 6px
      cursor: pointer

    &__choice
      margin-top: 3px
      margin-left: 3px
      cursor: pointer
    &__choice--selected
      box-shadow: 0 0 0 3px map-get($blue, 'base')
</style>
