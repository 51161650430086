<template>
  <v-sheet
    v-if="!checklistId"
    class="SecondaryFiltersDrawer"
    color="white"
  >
    <div class="SecondaryFiltersDrawer__count">
      <span
        class="SecondaryFiltersDrawer__count-label"
        v-text="$t('filter.SubsetIssues')"
      />
      <span
        v-if="projectId != null"
        class="SecondaryFiltersDrawer__count-value"
        v-text="getCounter('total')"
      />
    </div>

    <IssueFilterGroupsAndProjects
      v-if="projectId == null"
      :value="{
        selectedProjectIds: model.project || [],
        selectedGroupIds: model.groups || [],
      }"
      class="SecondaryFiltersDrawer__filter"
      @input="onGroupProjectInput"
    />

    <IssueFilterStatus
      v-model="model.status"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
      :additive-filters="false"
    />

    <IssueFilterTotalScore
      v-model="model.totalScore"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
      :show-more-button="!(value && value.probabilityScore && value.criticalityScore)"
      :show-more="expandScore"
      :additive-filters="false"
      :show-more-text="$t('filter.MoreOptions')"
      :show-less-text="$t('filter.LessOptions')"
      @update:showMore="expandScore = $event"
    >
      <template #more>
        <IssueFilterCheckboxScore
          v-if="expandScore || (value && value.probabilityScore)"
          v-model="model.probabilityScore"
          :project-id="projectId"
          :filter="combinedFilter"
          :additive-filters="false"
          score-prop="probabilityScore"
          :title="$t('filter.Probability')"
          class="SecondaryFiltersDrawer__filter SecondaryFiltersDrawer__filter--nested pb-4"
        />

        <IssueFilterCheckboxScore
          v-if="expandScore || (value && value.criticalityScore)"
          v-model="model.criticalityScore"
          :project-id="projectId"
          :filter="combinedFilter"
          :additive-filters="false"
          score-prop="criticalityScore"
          :title="$t('filter.Criticality')"
          class="SecondaryFiltersDrawer__filter SecondaryFiltersDrawer__filter--nested pb-0"
        />
      </template>
    </IssueFilterTotalScore>

    <IssueFilterOverdue
      v-model="model.overdue"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
    />

    <IssueFilterCompleted
      v-model="model.isCompleted"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
    />

    <IssueFilterCustom
      v-for="field in customFilterableFields"
      :key="field.name"
      class="SecondaryFiltersDrawer__custom-filter"
      :project-id="projectId"
      :field="field"
      :value="getCustomFieldValue(field)"
      :filter="combinedFilter"
      :additive-filters="false"
      @input="$set(model, field.name, $event)"
    />

    <IssueFilterIp
      v-if="projectId != null"
      v-model="model.ips"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
      :additive-filters="false"
    />

    <IssueFilterHostname
      v-if="projectId != null"
      v-model="model.hostnames"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
      :additive-filters="false"
    />

    <IssueFilterPort
      v-if="projectId != null"
      v-model="model.ports"
      class="SecondaryFiltersDrawer__filter"
      :project-id="projectId"
      :filter="combinedFilter"
      :additive-filters="false"
    />
  </v-sheet>
  <v-sheet
    v-else-if="projectId"
    class="SecondaryFiltersDrawer"
    color="white"
  >
    <div class="SecondaryFiltersDrawer__count">
      <span
        class="SecondaryFiltersDrawer__count-label"
        v-text="$t('filter.SelectChecklistsM')"
      />
    </div>

    <ChecklistDefaultView
      :project-id="projectId"
      :checklists="checklists"
      :show-done-status-bar="false"
      :show-checklist-progress-bar="false"
      :get-route="({ projectId, checklistId }) => ({
        name: projectId ? 'ProjectEditCard' : 'EditCard',
        props: projectId ? { projectId: projectId } : {},
        query: { checklistId: checklistId },
      })"
      :padding-left="24"
    />
  </v-sheet>
</template>

<script>
import axios from 'axios'
import * as R from 'ramda'

import { EMPTY_SET, ISSUE_FIELD_TYPE } from '../constants'
import { mergeIssueFilters } from '../helpers'

import IssueCounter from '../store/orm/issueCounter'

import ChecklistDefaultView from '../components/ChecklistDefaultView'
import IssueFilterCheckboxScore from '../components/IssueFilterCheckboxScore'
import IssueFilterCompleted from '../components/IssueFilterCompleted'
import IssueFilterCustom from '../components/IssueFilterCustom'
import IssueFilterGroupsAndProjects from '../components/IssueFilterGroupsAndProjects'
import IssueFilterHostname from '../components/IssueFilterHostname'
import IssueFilterIp from '../components/IssueFilterIp'
import IssueFilterPort from '@/components/IssueFilterPort'
import IssueFilterOverdue from '../components/IssueFilterOverdue'
import IssueFilterStatus from '../components/IssueFilterStatus'
import IssueFilterTotalScore from '../components/IssueFilterTotalScore'

// Input example: { totalScore: '1,2', status: 'new_issue' }
// Output example: { totalScore: ['1', '2'], status: ['new_issue'], ... }
const initModel = R.pipe(
  R.clone,
  R.defaultTo({}),
  R.map(R.pipe(R.split(','), R.filter(Boolean))),
  R.evolve({
    totalScore: R.map(Number),
    probabilityScore: R.map(Number),
    criticalityScore: R.map(Number),
    isCompleted: R.map(v => v === 'true'),
  }),
  R.over(R.lensProp('status'), R.defaultTo([])),
  R.over(R.lensProp('totalScore'), R.defaultTo([])),
  R.over(R.lensProp('probabilityScore'), R.defaultTo([])),
  R.over(R.lensProp('criticalityScore'), R.defaultTo([])),
  R.over(R.lensProp('overdue'), R.defaultTo([])),
  R.over(R.lensProp('isCompleted'), R.defaultTo([])),
  R.over(R.lensProp('ips'), R.defaultTo([])),
  R.over(R.lensProp('hostnames'), R.defaultTo([])),
  R.over(R.lensProp('ports'), R.defaultTo([])),
)

// opposite of `initModel`
const modelToFilter = R.pipe(
  R.filter(propModel => propModel.length > 0),
  R.map(R.join(',')),
)

const NO_COUNTER = '…'

export default {
  name: 'SecondaryFiltersDrawer',

  components: {
    ChecklistDefaultView,
    IssueFilterCheckboxScore,
    IssueFilterCompleted,
    IssueFilterCustom,
    IssueFilterHostname,
    IssueFilterGroupsAndProjects,
    IssueFilterIp,
    IssueFilterPort,
    IssueFilterOverdue,
    IssueFilterStatus,
    IssueFilterTotalScore,
  },

  props: {
    projectId: { type: String, default: null },
    checklistId: { type: String, default: null },

    // e.g.: { totalScore: '1,2', status: 'new_issue' }
    value: { type: Object, required: true }, // filter object
    baseFilter: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      model: initModel(this.value),

      expandScore: false,
    }
  },

  computed: {
    checklists() { return this.$store.state.checklist.checklists },

    // `baseFilter` narrowed by `value`
    combinedFilter() { return mergeIssueFilters(this.baseFilter, this.value) },

    counters() {
      const { projectId, combinedFilter } = this
      return IssueCounter.getOrDefault(projectId, combinedFilter, false)
    },

    filterableFields() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/filterableFields'](projectId)
    },

    customFilterableFields() {
      const { filterableFields } = this
      return filterableFields && R.filter(
        R.propEq('predefined', false),
        filterableFields,
      )
    },
  },

  watch: {
    // v-model
    value: {
      handler(v) {
        const newModel = initModel(v)
        if (!R.equals(this.model, newModel)) this.model = newModel
      },
      deep: true,
    },
    model: {
      handler(model) { this.$emit('input', modelToFilter(model)) },
      deep: true,
    },
  },

  mounted() {
    this.setupCountersWatcher()
  },

  methods: {
    setupCountersWatcher() {
      const unwatch = this.$watch(
        () => ([this.combinedFilter, this.filterableFields]),
        ([_, filterableFields]) => filterableFields != null && this.fetchCounters(),
        { deep: true, immediate: true },
      )
      this.$once('hook:beforeDestroy', unwatch)
    },

    async fetchCounters() {
      const { projectId, combinedFilter: filter } = this
      if (!projectId) throw new Error('Implemented only for in-project usage')
      if (filter === EMPTY_SET) return
      await IssueCounter.dispatch('$countCommon', { projectId, filter, additive: false })
        .catch((e) => axios.isCancel(e) ? console.info(e) : console.error(e))
    },

    getCounter(fieldName, fieldValue = null) {
      const { counters } = this
      if (fieldName === 'total') return counters?.total ?? NO_COUNTER
      return counters?.customFields?.[fieldName]?.[fieldValue] ??
        counters?.[fieldName]?.[fieldValue] ??
        NO_COUNTER
    },

    // getCustomFieldValue :: IssueSchemaField -> any[]
    getCustomFieldValue(field) {
      const { value: filter } = this
      const typeCast = R.cond([
        [R.propEq('type', ISSUE_FIELD_TYPE.INTEGER), x => parseInt(x, 10)],
        [R.propEq('type', ISSUE_FIELD_TYPE.FLOAT), Number],
        [R.T, R.always(R.identity)],
      ])(field)

      return (filter[field.name] || '')
        .split(',')
        .filter(Boolean)
        .map(typeCast)
    },

    onGroupProjectInput({ selectedProjectIds, selectedGroupIds }) {
      for (const [prop, value] of [
        ['project', selectedProjectIds],
        ['groups', selectedGroupIds],
      ]) {
        if (value?.length) {
          this.$set(this.model, prop, value)
        } else {
          this.$delete(this.model, prop)
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.SecondaryFiltersDrawer
  box-shadow: 0 2px 2px rgba(0, 0, 0, .12), 0 0 4px rgba(0, 0, 0, .05)

  &__count
    display: flex
    margin: 24px 0
    align-items: center
    justify-content: space-between
    padding: 0 40px
    min-height: 56px

  &__count-label
    font-weight: 500
    font-size: 18px
    line-height: 19px

  &__count-value
    font-weight: bold
    font-size: 48px
    line-height: 56px
</style>
